import {Component} from 'react';
import "bootstrap/dist/css/bootstrap.min.css";
import "./home.css";
import main from "./kavi1.jpg";
import Blog from "./blog.component";


export default class Home extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render(){
        return(<div className="container-fluid px-0" style={{"background-color":"#729B79","background-size": "cover"}}>
        <title>Kavi Mehta's Website</title>
        <a href="/admin/blogs"><button className="hiddenbutton">Access</button></a>
        <h2 className="title"> Welcome to <span className="span2">Kavi Mehta</span> (dot) com </h2> 
        <h3 style={{"color":"#FFF"}} >I am now  2 Years old..15 November 2021 was my birthday day....</h3>
        <img className="center" src={main} width="200" alt="I am and can walk!!!Ultimate freedom..."/>
        <Blog/>
    </div>);
    }

    }